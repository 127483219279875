import React, { ChangeEvent, FC, KeyboardEvent, useState } from 'react';
import style from './TimeInput.module.scss';

interface TimeInputProps {
  onChange?(value: string): void;
  placeholder?: string;
}

const TimeInput: FC<TimeInputProps> = ({ onChange, placeholder }) => {
  const [value, setValue] = useState<string>('');

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    let inputValue = e.target.value.replace(/[^\d:]/g, ''); // Allow only digits and colons

    // Limit the length to HH:MM:SS (8 characters max)
    if (inputValue.length > 8) {
      inputValue = inputValue.slice(0, 8);
    }

    // Automatically insert colons for the HH:MM:SS format
    if (inputValue.length === 2 && !inputValue.includes(':')) {
      inputValue += ':';
    } else if (inputValue.length === 5 && inputValue.lastIndexOf(':') === 2) {
      inputValue += ':';
    }

    // Split into hours, minutes, and seconds
    const [hours, minutes, seconds] = inputValue.split(':');

    // Validate minutes and seconds (both should be <= 59)
    if (minutes && parseInt(minutes) > 59) {
      inputValue = `${hours || ''}:${'59'}:${seconds || ''}`;
    }

    if (seconds && parseInt(seconds) > 59) {
      inputValue = `${hours || ''}:${minutes || ''}:${'59'}`;
    }

    setValue(inputValue);
    onChange && onChange(inputValue);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Backspace' && value.endsWith(':')) {
      e.preventDefault();
      setValue(value.slice(0, -1));
      onChange && onChange(value.slice(0, -1));
    }
  };

  return (
    <input
      type="text"
      value={value}
      onChange={handleInputChange}
      onKeyDown={handleKeyDown}
      placeholder={placeholder ? `${placeholder} HH:MM:SS` : 'HH:MM:SS'}
      maxLength={8}
      className={style.timeInput}
      aria-label="Enter video time in hh:hh:ss format"
    />
  );
};

export default TimeInput;
