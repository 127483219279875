export function getExtensionFromSignedUrl(path: string): string {
  return path.split('?').shift()?.split('/').pop()?.split('.').pop() as string;
}

export function downloadFile(data: Blob, fileName: string): void {
  const link = document.createElement('a');
  // create a blobURI pointing to our Blob
  link.href = URL.createObjectURL(data);
  link.target = '_blank';
  link.download = fileName;
  // some browser needs the anchor to be in the doc
  document.body.append(link);
  link.click();

  // in case the Blob uses a lot of memory
  setTimeout(() => {
    document.body.removeChild(link);
    URL.revokeObjectURL(link.href);
  }, 1000);
}

export function msToHoursMinutes(milliseconds: number): string {
  if (milliseconds < 0) {
    throw new Error('Input milliseconds must be non-negative.');
  }

  const hours = Math.floor(milliseconds / (1000 * 60 * 60));
  const remainingMilliseconds = milliseconds % (1000 * 60 * 60);
  const minutes = Math.floor(remainingMilliseconds / (1000 * 60));

  if (hours === 0) {
    return `${minutes} min`;
  } else {
    return `${hours} hour${hours > 1 ? 's' : ''} ${minutes} min`;
  }
}

export function toHHMMSS(input: string | number): string {
  let totalSeconds: number;

  if (typeof input === 'number') {
    totalSeconds = Math.max(0, input);
  } else {
    const parts = input.split(':').map(Number).reverse();
    totalSeconds = parts[0] * 1 + (parts[1] || 0) * 60 + (parts[2] || 0) * 3600;
  }
  const hours: number = Math.floor(totalSeconds / 3600);
  const minutes: number = Math.floor((totalSeconds % 3600) / 60);
  const seconds: number = totalSeconds % 60;
  return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
}

function pad(num: number): string {
  return num.toString().padStart(2, '0');
}
