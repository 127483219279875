/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import React, { FC, useEffect, useRef } from 'react';
import { useRegions } from '@/hooks/useRegions';

export interface ControlsProps {
  onEvent?(data: unknown): void;
}

export type ElementState = 'normal' | 'active' | 'disabled';
export type ElementType =
  | 'toggleRegions'
  | 'deleteRegions'
  | 'createRegions'
  | 'assignRegions'
  | 'produceRegions'
  | 'lockRegions';

const Controls: FC<ControlsProps> = (props: ControlsProps) => {
  const controlsRef = useRef<HTMLDivElement>(null);

  const {
    handleRegionsAssigned,
    handleRegionsDeleted,
    handleRegionCreated,
    handleRegionsLocked,
    handleRegionsProduce,
    handleRegionsVisibilityChanged,
  } = useRegions();

  useEffect(() => {
    console.log('Controls Updated, rebinding events');
    if (!controlsRef.current) return;
    const children = controlsRef.current.childNodes;
    const clickListener = (e: any): void => {
      const _evt = e.target.dataset.key as string;
      console.log('Control event', _evt, e);
      handleControlEvent(_evt);
    };
    children.forEach((child: ChildNode) => {
      child.addEventListener('click', clickListener);
    });
    return () => {
      children.forEach((child: ChildNode) => {
        child.removeEventListener('click', clickListener);
      });
    };
  }, []);

  useEffect(() => {
    const controlKeysListener = (e: KeyboardEvent): void => {
      // if (e.key === 'Backspace') {
      //   e.preventDefault();
      //   handleControlEvent('deleteRegions');
      // }
      if (e.key.toUpperCase() === 'A') {
        e.preventDefault();
        handleControlEvent('assignRegions');
      }
      if (e.key.toUpperCase() === 'L') {
        e.preventDefault();
        handleControlEvent('lockRegions');
      }
      if (e.key.toUpperCase() === 'D') {
        e.preventDefault();
        handleControlEvent('createRegions');
      }
      if (e.key.toUpperCase() === 'Q') {
        e.preventDefault();
        handleControlEvent('toggleRegions');
      }
      if (e.key.toUpperCase() === 'R' && e.ctrlKey) {
        e.preventDefault();
        handleControlEvent('produceRegions');
      }
    };

    window.addEventListener('keydown', controlKeysListener);
    // console.log("Spacebar Listener Added", window);
    return () => {
      // console.log("Spacebar Listener Removed", window);
      window.removeEventListener('keydown', controlKeysListener);
    };
  }, []);

  const handleControlEvent = (event: string): void => {
    console.log(`Trigger Event ${event}`);
    switch (event) {
      case 'assignRegions':
        handleRegionsAssigned && handleRegionsAssigned();
        break;
      case 'deleteRegions':
        handleRegionsDeleted && handleRegionsDeleted();
        break;
      case 'toggleRegions':
        handleRegionsVisibilityChanged && handleRegionsVisibilityChanged();
        break;
      case 'createRegions':
        handleRegionCreated && handleRegionCreated();
        break;
      case 'produceRegions':
        handleRegionsProduce && handleRegionsProduce();
        break;
      case 'lockRegions':
        handleRegionsLocked && handleRegionsLocked();
        break;
    }
  };
  const controlElement = (control: ElementType): React.ReactNode => {
    const button_classes: Record<ElementState, string> = {
      normal: 'bg-gray-800 text-neutral-100 p-2 rounded-full hover:bg-gradcut-900',
      active: 'bg-gray-800 text-neutral-100 p-2 rounded-full hover:bg-gradcut-900',
      disabled: 'bg-gray-800 text-neutral-400 p-2 rounded-full',
    };
    const _button_class = button_classes['normal'];
    return (
      <>
        <button className={_button_class} data-key={control}>
          {control}
        </button>
      </>
    );
  };

  return (
    <div ref={controlsRef} className="grid grid-cols-3   bg-neutral-800 p-4 shadow-xl rounded-t-lg">
      <div className="col-start-2 flex flex-row justify-center gap-4 items-center">
        {/* {controlElement('toggleRegions')} */}
        {/* {controlElement('deleteRegions')} */}
        {controlElement('createRegions')}
      </div>
      <div className="flex flex-row justify-center gap-4 items-center">
        {/* {controlElement('lockRegions')} */}
        {/* {controlElement('assignRegions')} */}
        {/* {controlElement('produceRegions')} */}
      </div>
    </div>
  );
};

export default Controls;
