import React from 'react';
import ReactDOM from 'react-dom';

import { QueryClient, QueryClientProvider } from 'react-query';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import AuthProvider from './components/AuthProvider';
import { createTheme, ThemeProvider } from '@mui/material';
import { SanityProvider } from './contexts/SanityContext';
import { createClient } from '@sanity/client';
import { SnackbarProvider } from 'notistack';
import { UploadProvider } from '@/contexts/UploadContext';
import { UserProvider } from './contexts/UserContext';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { WorkbenchProvider } from './contexts/WorkbenchContext';

/* istanbul ignore file */

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const theme = createTheme({
  typography: {},
  components: {},
  palette: {
    primary: {
      main: '#191C43',
    },
    secondary: {
      main: '#848AD1',
    },
  },
});

const sanityClient = createClient({
  projectId: process.env.REACT_APP_SANITY_PROJECT_ID, // find this at manage.sanity.io or in your sanity.json
  dataset: 'production', // this is from those question during 'sanity init'
  useCdn: true,
  apiVersion: '2022-01-12',
});

ReactDOM.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId="286495038780-63fnvb0lb4jnblq2uslk2cma0qvs8b17.apps.googleusercontent.com">
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <SanityProvider client={sanityClient}>
              <SnackbarProvider anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <WorkbenchProvider>
                  <UploadProvider>
                    <UserProvider
                      userInitialState={{
                        trialDays: 0,
                        SubscriptionState: [],
                      }}
                    >
                      <BrowserRouter>
                        <App />
                      </BrowserRouter>
                    </UserProvider>
                  </UploadProvider>
                </WorkbenchProvider>
              </SnackbarProvider>
            </SanityProvider>
          </QueryClientProvider>
        </ThemeProvider>
      </AuthProvider>
    </GoogleOAuthProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
