/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */

import React, { FC, MutableRefObject, useCallback, useEffect, useMemo, useRef } from 'react';
import { WaveForm, WaveSurfer as WaveSurferComponent } from 'wavesurfer-react';
import WaveSurfer from 'wavesurfer.js';
import RegionsPlugin, { RegionParams } from 'wavesurfer.js/dist/plugins/regions';
import TimelinePlugin from 'wavesurfer.js/dist/plugins/timeline';
import { PluginType } from 'wavesurfer-react/src/types';
import { Region } from 'wavesurfer.js/dist/plugins/regions';
import GCMinimapPlugin from '@/lib/GCMinimapPlugin';
import { Fragment } from './AVDisplay';

export interface ExtRegionParams extends RegionParams {
  assigned: boolean;
  selected: boolean;
  locked: boolean;
  updating: boolean;
}

export interface ExtRegion extends Region {
  _unsubscribe?(): void;
  _subscribe?(e: MouseEvent): void;
  selected?: boolean;
  locked?: boolean;
  edited?: boolean;
  fragment: Fragment;
  regionParams: ExtRegionParams;
}

export interface WaveformProps {
  onComponentLoad?(waveSurfer: WaveSurfer): void;
  onReady?(waveSurfer: WaveSurfer): void;
  regionsVisible?: boolean;
}

export interface ExtRegionParams extends RegionParams {
  assigned: boolean;
  selected: boolean;
  locked: boolean;
  updating: boolean;
  fragment: Fragment;
}

const Waveform: FC<WaveformProps> = ({ onComponentLoad, onReady, regionsVisible }) => {
  const videoRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
  const wavesurferRef: MutableRefObject<WaveSurfer | null> = useRef(null);
  const regionsContainerRef = useRef<HTMLDivElement | null>(null);

  const plugins: PluginType<any>[] = useMemo(() => {
    return [
      {
        key: 'minimap',
        plugin: GCMinimapPlugin,
        options: { insertPosition: 'beforebegin' },
      },
      {
        key: 'regions',
        plugin: RegionsPlugin,
        options: { dragSelection: true },
      },
      {
        key: 'top-timeline',
        plugin: TimelinePlugin,
        options: {
          height: 20,
          insertPosition: 'beforebegin',
          style: {
            color: '#000',
          },
        },
      },
      {
        key: 'bottom-timeline',
        plugin: TimelinePlugin,
        options: {
          height: 20,
          style: {
            color: '#000',
          },
        },
      },
    ].filter(Boolean);
  }, []);

  useEffect(() => {
    if (!regionsContainerRef.current) return;
    regionsContainerRef.current.style.visibility = regionsVisible ? 'visible' : 'hidden';
  }, [regionsVisible]);

  const handleWSMount = useCallback(
    (waveSurfer: WaveSurfer | null) => {
      // console.log('Waveform: handleWSMount', waveSurfer);
      if (!waveSurfer) {
        return;
      }
      wavesurferRef.current = waveSurfer;
      // console.log('WaveSurfer plugins', waveSurfer.getActivePlugins());

      waveSurfer.getWrapper().addEventListener('scroll', (e) => {
        e.preventDefault();
        e.stopPropagation();
      });
      // console.log('calling onComponentLoad');
      onComponentLoad && onComponentLoad(waveSurfer);

      waveSurfer.on('ready', () => {
        console.log('WaveSurfer is ready');
        onReady && onReady(waveSurfer);
      });

      waveSurfer.on('loading', (data) => {
        console.log('loading --> ', data);
      });

      waveSurfer.on('error', (data) => {
        console.log('error --> ', data);
      });

      const rp = waveSurfer.getActivePlugins()[1] as any;
      rp.___addRegion = rp.addRegion;

      rp.addRegion = function (regionParams: ExtRegionParams) {
        const result = rp.___addRegion(regionParams as RegionParams) as ExtRegion;
        result.locked = regionParams.locked;
        return result as Region;
      };
      const element = rp.regionsContainer;
      element.id = 'regions-container';
      regionsContainerRef.current = waveSurfer.getWrapper().querySelector('#regions-container');
    },
    [onComponentLoad, onReady],
  );

  const waveColor = [
    // an array of colors, to be applied as gradient color stops to the waveform.
    'rgba(255,0,255,.2)',
    'rgba(255,0,0,.5)',
    'rgba(255,150,0,.9)',
    'rgba(255,0,0,.5)',
    'rgba(255,0,255,.2)',
  ];

  const progressColor = [
    // the gradient fill styles are also available on the progressColor option
    'rgba(255,0,255,.2)',
    'rgba(255,0,0,.5)',
    'rgba(255,150,0,.7)',
    'rgba(255,0,0,.5)',
    'rgba(255,0,255,.2)',
  ];

  return (
    <>
      <div ref={videoRef}>
        <WaveSurferComponent
          plugins={plugins}
          onMount={handleWSMount}
          cursorColor="#606060"
          container="#waveform"
          minPxPerSec={10}
          backend={'MediaElement'}
          waveColor={waveColor}
          progressColor={progressColor}
          autoScroll={false}
          autoCenter={false}
        >
          <WaveForm id={'waveform'} />
          <div id="timeline" />
        </WaveSurferComponent>
      </div>
    </>
  );
};

export default Waveform;
