import { BundleListItems, UserListItem, videoCutI } from '@/types/user-list-item';
import { useMutation, UseMutationResult, useQuery, UseQueryResult } from 'react-query';
import Api from './api';

export const LIST_USERS_CACHE_KEY = 'list-users';
export const LIST_BUNDLE_ITEMS_CACHE_KEY = 'list-bundle-items';

export function useGetUserList(searchValue: string): UseQueryResult<UserListItem[]> {
  return useQuery([LIST_USERS_CACHE_KEY, searchValue], async () => {
    return await Api.get(`/users/${searchValue ? searchValue : 'default'}`);
  });
}

export function useGetBundleItems(): UseQueryResult<BundleListItems[]> {
  return useQuery(LIST_BUNDLE_ITEMS_CACHE_KEY, async () => {
    return await Api.get('/bundleItems');
  });
}

export function useVideCut(): UseMutationResult<void, unknown, videoCutI> {
  return useMutation(
    async ({ start, duration, sourceFilePath, projectId }) => {
      return Api.put<void>(`/video/cut`, {
        start,
        duration,
        sourceFilePath,
        projectId,
      } as unknown as void);
    },
    {
      onSuccess: () => console.log('video cut'),
    },
  );
}
