import { Fragment } from '@/components/Videoplayer/AVDisplay';
import { ExtRegionParams } from '@/components/Videoplayer/Waveform';
import { Worker } from '@/lib/PeakDataLoader';
import React, { useState } from 'react';

export interface WorkbenchContextValue {
  workbenchState: any;
  setWorkbenchState: React.Dispatch<React.SetStateAction<any>>;
  selectStartRegion: string | null;
  setSelectStartRegion: React.Dispatch<React.SetStateAction<string | null>>;
  selectEndRegion: string | null;
  setSelectEndRegion: React.Dispatch<React.SetStateAction<string | null>>;
  worker?: Worker;
  currentPosition?: number;
  fragments: Fragment[];
  setFragments: React.Dispatch<React.SetStateAction<Fragment[]>>;
  regionParams: ExtRegionParams[];
  setRegionParams: React.Dispatch<React.SetStateAction<ExtRegionParams[]>>;
  setCurrentPosition: React.Dispatch<React.SetStateAction<number>>;
}

export const WorkbenchContext = React.createContext<WorkbenchContextValue>({
  workbenchState: {},
  setWorkbenchState: () => {
    return;
  },
  selectStartRegion: null,
  setSelectStartRegion: () => {
    return;
  },
  selectEndRegion: null,
  setSelectEndRegion: () => {
    return;
  },
  fragments: [],
  setFragments: () => {
    return;
  },
  regionParams: [],
  setRegionParams: () => {
    return;
  },
  setCurrentPosition: () => {
    return;
  },
});

export interface VideoProviderProps {
  children: React.ReactNode;
}

export const WorkbenchProvider: React.FC<VideoProviderProps> = ({
  children,
}: VideoProviderProps) => {
  const [workbenchState, setWorkbenchState] = useState({});
  const [selectStartRegion, setSelectStartRegion] = useState<string | null>(null);
  const [selectEndRegion, setSelectEndRegion] = useState<string | null>(null);
  const [fragments, setFragments] = useState<Fragment[]>([]);
  const [regionParams, setRegionParams] = useState<ExtRegionParams[]>([]);
  const [currentPosition, setCurrentPosition] = useState<number>(0);
  return (
    <WorkbenchContext.Provider
      value={{
        workbenchState,
        setWorkbenchState,
        selectStartRegion,
        setSelectStartRegion,
        selectEndRegion,
        setSelectEndRegion,
        fragments,
        setFragments,
        regionParams,
        setRegionParams,
        currentPosition,
        setCurrentPosition,
      }}
    >
      {children}
    </WorkbenchContext.Provider>
  );
};
