/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable */
import { FC, useContext, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import './App.scss';
import * as urls from './routePaths';

import { useGetSubscribedCustomerInfo } from '@/adapters/subscription';
import AppLayout from '@/components/AppLayout';
import {
  Adjustments,
  SubscribedCustomerInfo,
  SubscriptionState,
  UserSubscriptionState,
} from '@/entities/subscription';
import { useAuth0 } from '@auth0/auth0-react';
import Api from './adapters/api';
import ProtectedArea from './components/ProtectedArea';
import { UserContext } from './contexts/UserContext';
import useScript from './hooks/useScript';
import BrandKit from './pages/BrandKit';
import Checkout from './pages/Checkout';
import Integrations from './pages/Integrations';
import StreamKeys from './pages/StreamKeys';
import OrderHistory from './pages/Subscription/OrderHistory';
import Plans from './pages/Subscription/Plans';
import ProjectsRoutes from './routes/Projects';
import UsersRoutes from './routes/Users';
import useAuthorization from './hooks/useAuthorization';
import Workbench from './pages/Workbench/Workbench';

type DbUserType = {
  id: string;
  role: string;
  uploadDuration: number;
};
const App: FC = () => {
  // const [userState, setUserState] = useState<UserState>({
  //   plan: '',
  //   trialDays: 0,
  //   state: true,
  // });
  const { userState, setUserState } = useContext(UserContext);
  const { hasRole } = useAuthorization();

  const [dbUser, setDbUser] = useState<DbUserType>({ id: '', role: '', uploadDuration: 0 });
  const { isAuthenticated, getAccessTokenSilently, getIdTokenClaims, user } = useAuth0();
  const email: string | undefined = user?.email;
  const { data: customerInfo }: { data: SubscribedCustomerInfo | undefined } =
    useGetSubscribedCustomerInfo(user?.sub ?? '');
  useEffect(() => {
    const setAuthResult = async (): Promise<void> => {
      const authToken = await getAccessTokenSilently();
      const claims = await getIdTokenClaims();
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const idToken = claims!.__raw;

      Api.setAuthResult({ authToken, idToken });
    };

    if (isAuthenticated) {
      void setAuthResult();
    }
  }, [isAuthenticated, getAccessTokenSilently, getIdTokenClaims]);

  useEffect(() => {
    window.addEventListener('error', (e) => {
      if (e.message === 'ResizeObserver loop limit exceeded') {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div',
        );
        const resizeObserverErr = document.getElementById('webpack-dev-server-client-overlay');
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (email) {
        try {
          const response: any = await Api.get(`user/findByEmail/${email}`);
          if (response) {
            setDbUser(response);
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }
    };

    fetchData();

    // Cleanup function (optional):
    return () => {
      // Perform any cleanup actions here, if necessary
    };
  }, [customerInfo]);

  useEffect(() => {
    if (customerInfo && !customerInfo.error) {
      const { trial_days, subscription_status }: SubscribedCustomerInfo = customerInfo;
      let tempObj: Adjustments | undefined = undefined;
      const obj: UserSubscriptionState[] = [];
      if (subscription_status.length > 0) {
        subscription_status.map((item: SubscriptionState) => {
          if (item.status === 'active') {
            if (item.price_lookup_key.includes('monthly')) {
              // Assuming you have the following variables
              const currentPrice = 49.95; // Monthly subscription price
              const upgradePrice = 480; // New upgraded subscription price
              const startDate = new Date(item.start_date * 1000); // Replace with actual start date
              const currentDate = new Date(); // Current date

              // Calculate the number of days remaining in the current billing period
              const daysInMonth = 30; // Assuming a 30-day billing period
              const daysRemaining: number = Math.max(
                0,
                daysInMonth -
                  Math.floor((currentDate.getTime() - startDate.getTime()) / (24 * 60 * 60 * 1000)),
              );

              // Calculate the used amount
              const usedAmount = (currentPrice / daysInMonth) * (daysInMonth - daysRemaining);

              // Calculate the prorated amount for the remaining days
              const proratedAmount = (upgradePrice / daysInMonth) * daysRemaining;

              // Calculate the upgrade total
              const upgradeTotal = usedAmount + proratedAmount;

              tempObj = {
                usedAmount,
                proratedAmount,
                upgradeTotal,
              };
            }
            obj.push({
              plan: item.price_lookup_key,
              state: true,
              start_date: item.start_date,
              next_billing_date: item.next_billing_date,
              ...(tempObj && { adjustments: tempObj }),
            });
            tempObj = undefined;
          }
          return true;
        });
      }
      setUserState &&
        setUserState({
          trialDays: Math.abs(trial_days) > 14 ? 0 : trial_days,
          SubscriptionState: obj,
          bannerActive: obj.length > 0 ? false : true,
          role: dbUser?.role,
          id: dbUser?.id,
          uploadDuration: dbUser?.uploadDuration,
        });
    }
  }, [customerInfo, setUserState, dbUser]);

  if (process.env.REACT_APP_ZENDESK_ID && process.env.REACT_APP_ZENDESK_SCRIPT) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useScript(process.env.REACT_APP_ZENDESK_SCRIPT, process.env.REACT_APP_ZENDESK_ID);
  }

  return (
    // <UserProvider userInitialState={userState ?? { plan: '', trialDays: 14, state: false }}>
    <Routes>
      <Route element={<AppLayout />}>
        {ProjectsRoutes}
        {UsersRoutes()}
        <Route
          path={urls.BRAND_KIT_URL}
          element={
            <ProtectedArea>
              <BrandKit />
            </ProtectedArea>
          }
        />
        <Route
          path={urls.STREAM_KEYS_URL}
          element={
            <ProtectedArea>
              <StreamKeys />
            </ProtectedArea>
          }
        />
        <Route
          path={urls.WORKBENCH_URL}
          element={
            <ProtectedArea>
              <Workbench />
            </ProtectedArea>
          }
        />
        {!hasRole(['temporary_enterprise', 'enterprise', 'super_admin']) && (
          <Route
            path={urls.SUBSCRIPTION_HISTORY_URL}
            element={
              <ProtectedArea>
                <OrderHistory />
              </ProtectedArea>
            }
          />
        )}
        <Route
          path={urls.SUBSCRIPTION_PLANS_URL}
          element={
            <ProtectedArea>
              <Plans />
            </ProtectedArea>
          }
        />
        <Route
          path={urls.CHECKOUT_URL}
          element={
            <ProtectedArea>
              <Checkout />
            </ProtectedArea>
          }
        />
        {!hasRole(['standard']) && (
          <Route
            path={urls.INTEGRATIONS_URL}
            element={
              <ProtectedArea>
                <Integrations />
              </ProtectedArea>
            }
          />
        )}
      </Route>
      <Route path="/" element={<Navigate to={urls.PROJECTS_URL} />} />
    </Routes>
    // </UserProvider>
  );
};

export default App;
