/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { AVComponent } from '@/components/Videoplayer/AVDisplay';
import { useCallback, useEffect, useMemo, useState } from 'react';
import PeakDataProcessor from '@/lib/PeakDataProcessor';
import { PeakDataLoader } from '@/lib/PeakDataLoader';

export interface AudioLoader {
  onAvComponentReady(data: AVComponent): void;
  avComponentRef: AVComponent | null;
}

export function useAudioLoader(): AudioLoader {
  const [avComponentRef, setAVComponentRef] = useState<AVComponent | null>(null);
  const pdp: PeakDataProcessor = useMemo(() => new PeakDataProcessor(), []);
  const pdl: PeakDataLoader = useMemo(() => new PeakDataLoader(), []);

  const onAvComponentReady = (data: AVComponent): void => {
    console.log('AVComponent ready', data);
    setAVComponentRef(data);
    initWaveform().catch(console.error);
  };

  useEffect(() => {
    console.log('useEffect', avComponentRef, pdp);
    if (avComponentRef) {
      const urlSearchParams = new URL(avComponentRef.video.src).searchParams;
      pdl.update(
        avComponentRef.audioRef,
        `${process.env.REACT_APP_WORKER_URL as string}/api/convert?key=${
          urlSearchParams.get('key') as string
        }`,
        pdp,
      );
    }
  }, [avComponentRef, pdp]);

  const initWaveform = useCallback(async () => {
    if (!avComponentRef) return;
    pdl.start();
    const wavesurfer = avComponentRef.audioRef;
    console.log('Initializing Waveform with silent track');
    await pdp.updateWavesurfer(wavesurfer);

    // await loadNextChunk();
  }, [avComponentRef]);

  return { onAvComponentReady, avComponentRef };
}
