/**
 * Minimap is a tiny copy of the main waveform serving as a navigation tool.
 */
import WaveSurfer, { WaveSurferOptions } from 'wavesurfer.js';
import { BasePlugin, BasePluginEvents } from 'wavesurfer.js/dist/base-plugin';
import createElement from 'wavesurfer.js/dist/dom.js';

export type MinimapPluginOptions = {
  overlayColor?: string;
  insertPosition?: InsertPosition;
} & Partial<WaveSurferOptions>;

const defaultOptions = {
  height: 50,
  overlayColor: 'rgba(100, 100, 100, 0.1)',
  insertPosition: 'afterend',
};

export type MinimapPluginEvents = BasePluginEvents & {
  ready: [];
  interaction: [number];
};

class GCMinimapPlugin extends BasePlugin<MinimapPluginEvents, MinimapPluginOptions> {
  protected options: MinimapPluginOptions & typeof defaultOptions;
  private minimapWrapper: HTMLElement;
  private miniWavesurfer: WaveSurfer | null = null;
  private overlay: HTMLElement;
  private container: HTMLElement | null = null;

  constructor(options: MinimapPluginOptions) {
    super(options);
    this.options = Object.assign({}, defaultOptions, options);

    this.minimapWrapper = this.initMinimapWrapper();
    this.overlay = this.initOverlay();
  }

  public static create(options: MinimapPluginOptions): GCMinimapPlugin {
    return new GCMinimapPlugin(options);
  }

  /** Called by wavesurfer, don't call manually */
  onInit(): void {
    if (!this.wavesurfer) {
      throw Error('WaveSurfer is not initialized');
    }

    if (this.options.container) {
      if (typeof this.options.container === 'string') {
        this.container = document.querySelector(this.options.container) as HTMLElement;
      } else if (this.options.container instanceof HTMLElement) {
        this.container = this.options.container;
      }
      this.container?.appendChild(this.minimapWrapper);
    } else {
      this.container = this.wavesurfer.getWrapper().parentElement;
      this.container?.insertAdjacentElement(this.options.insertPosition, this.minimapWrapper);
    }

    this.initWaveSurferEvents();

    this.initMinimap();
  }

  private initMinimapWrapper(): HTMLElement {
    return createElement('div', {
      part: 'minimap',
      style: {
        position: 'relative',
      },
    });
  }

  private initOverlay(): HTMLElement {
    return createElement('div', {});
  }

  private initMinimap(): void {
    if (this.miniWavesurfer) {
      this.miniWavesurfer.destroy();
      this.miniWavesurfer = null;
    }

    if (!this.wavesurfer) return;

    const data = this.wavesurfer.getDecodedData();
    const media = this.wavesurfer.getMediaElement();
    if (!data) return;

    const peaks = [];
    for (let i = 0; i < data.numberOfChannels; i++) {
      peaks.push(data.getChannelData(i));
    }

    this.miniWavesurfer = WaveSurfer.create({
      ...this.options,
      container: this.minimapWrapper,
      minPxPerSec: 0,
      fillParent: true,
      dragToSeek: true,
      interact: true,
      waveColor: '#000',
      progressColor: '#c0c0c0',
      cursorColor: '#ff0000',
      media,
      peaks,
      duration: data.duration,
    });
    this.miniWavesurfer.on('ready', () => {
      const wavesurfer = this.wavesurfer;
      if (!wavesurfer) return;
      const miniws = this.miniWavesurfer;
      if (!miniws) return;
      wavesurfer.on('interaction', (data) => {
        miniws.setTime(data);
      });
      wavesurfer.on('redraw', () => {
        const _opts = wavesurfer.options;
        miniws.setOptions({
          peaks: _opts.peaks,
          duration: _opts.duration,
          sampleRate: _opts.sampleRate,
        });
      });
    });

    this.subscriptions.push(
      this.miniWavesurfer.on('ready', () => {
        this.emit('ready');
      }),

      this.miniWavesurfer.on('interaction', (data) => {
        this.emit('interaction', data);
      }),
    );
  }

  private getOverlayWidth(): number {
    const waveformWidth = this.wavesurfer?.getWrapper().clientWidth || 1;
    return Math.round((this.minimapWrapper.clientWidth / waveformWidth) * 100);
  }

  private onRedraw(): void {
    const overlayWidth = this.getOverlayWidth();
    this.overlay.style.width = `${overlayWidth}%`;
  }

  private onScroll(startTime: number): void {
    if (!this.wavesurfer) return;
    const duration = this.wavesurfer.getDuration();
    this.overlay.style.left = `${(startTime / duration) * 100}%`;
  }

  private initWaveSurferEvents(): void {
    if (!this.wavesurfer) return;

    this.subscriptions.push(
      this.wavesurfer.on('decode', () => {
        this.initMinimap();
      }),

      this.wavesurfer.on('scroll', (startTime: number) => {
        this.onScroll(startTime);
      }),

      this.wavesurfer.on('redraw', () => {
        this.onRedraw();
      }),
    );
  }

  /** Unmount */
  public destroy(): void {
    this.miniWavesurfer?.destroy();
    this.minimapWrapper.remove();
    super.destroy();
  }
}

export default GCMinimapPlugin;
