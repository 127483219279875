/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import WaveSurfer from 'wavesurfer.js';

export default class PeakDataProcessor {
  DOWNSCALE_FACTOR = 60;
  private BYTES_PER_SAMPLE = 2;
  // private samplerate = 3000 / this.DOWNSCALE_FACTOR;
  private samplerate = 16;
  private duration = 6 * 60 * 60; // 6 hours
  private datasize = this.samplerate * this.duration;
  private dur_increment = 20000; // 4 minutes
  private peaks: number[] = Array(this.datasize).fill(0);
  private peakseek = 0;
  private lastUpdatePeakSize = 0;
  private uuid = Math.random().toString(36).substring(7);

  protected toHexString(bytestr: string): string {
    let s = '';
    const byteArray = Array.from(Buffer.from(bytestr));
    byteArray.forEach(function (byte) {
      // s += ('--' + (byte & 0xFF).toString(16)).slice(-2);
      // s += "0x" + (byte & 0xFF).toString(16).slice(-2) + " ";
      s += '' + byte + ' ';
    });
    return s;
  }

  protected _toHexString(bytestr: Int8Array): string {
    let s = '';
    const byteArray = Array.from(Buffer.from(new Uint8Array(bytestr.buffer)));
    byteArray.forEach(function (byte) {
      // s += ('--' + (byte & 0xFF).toString(16)).slice(-2);
      // s += "0x" + (byte & 0xFF).toString(16).slice(-2) + " ";
      s += '' + byte.toString(16).padStart(2, '0') + ' ';
    });
    return s;
  }

  public async updateWavesurfer(wavesurfer: WaveSurfer): Promise<void> {
    this.lastUpdatePeakSize = this.peaks.length;
    const _dur = wavesurfer.getDuration();
    if (_dur !== this.duration) {
      console.log('Updating duration', this.duration, 'to', _dur);
      await wavesurfer.load('', [this.peaks], this.duration);
    } else {
      wavesurfer.setOptions({
        url: '',
        peaks: [this.peaks],
        duration: this.duration,
        sampleRate: this.samplerate,
      });
    }
  }

  public once = false;
  private prev_frame = '';

  public processStatsLog(data: string): void {
    const raw = data.split('\n');
    const last = raw.pop() || '';

    let object: any = {};
    const peaks: any[] = [];
    raw.map((_val, row_idx) => {
      if (this.prev_frame) {
        // console.log("Rebuilt:", this.prev_frame + _val)
        _val = this.prev_frame + _val;
        this.prev_frame = '';
      }
      const kv = _val.split(/[\s=:]+/);
      kv.map((item: string, index) => {
        if (item === 'frame' && row_idx >= 0) {
          // console.log(row_idx, object);
          object = {};
          peaks.push(object);
        }
        if (index % 2 === 0) {
          object[item] = kv[index + 1];
        }
      });
    });
    // console.log(peaks);
    const peakdata = peaks.map((item: any) => {
      // console.log(item["frame"], item["pts_time"]);
      return (1 / +item['lavfi.astats.Overall.Peak_level']) * 5;
    });
    this.updatePeakData(peakdata);
    this.prev_frame = last;
  }

  public updatePeakData(peakdata: number[]): void {
    const _peaksize = peakdata.length;
    const _newpeakseek = this.peakseek + _peaksize;

    if (this.datasize < _newpeakseek) {
      const increment = Math.max(this.dur_increment, _peaksize);
      this.datasize += increment;
      this.duration = this.datasize / this.samplerate;
      this.peaks.length = this.datasize;
    }

    try {
      for (let i = 0; i < _peaksize; i++) {
        this.peaks[this.peakseek + i] = peakdata[i];
      }
    } catch (e) {
      console.error('Error updating peaks:', e);
      throw e;
    }
    this.peakseek = _newpeakseek;
  }

  public processRawWaveData(bytes: Int8Array | null): void {
    if (!bytes) {
      return;
    }
    // if(this.once) {
    //     return;
    //
    // }
    // this.once = true;
    // const _blen = bytes.length;
    // console.log("Processor id: ", this.uuid,"; Processing raw data", _blen, "bytes, ", _blen / 2, "samples", _blen / 2 / this.samplerate, "seconds " +
    //     "at samplerate", this.samplerate, "Hz, current seek at ", this.peakseek, "bytes, ", this.peakseek / this.samplerate, "seconds");
    // console.log(this._toHexString(bytes));

    let currval = 0;
    const peakdata: any[] = [];
    // let sign = 0;
    let samples = [];
    let prev_peak = 0;
    const dv = new DataView(bytes.buffer);

    // for (let i = 0; i < bytes.length; i++) {
    //
    //     if (i % 2 == 0) {
    //         sign = bytes[i] & (1 << 7);
    //         currval = (((bytes[i] & 0xFF) << 8));
    //         currval = bytes[i] << 8;
    //         // if(i > 120 && i <300 ){
    //         // console.log(i.toString().padStart(3,'0'), "0>>", dv.getInt16(i, true));
    //         // console.log(i.toString().padStart(3,'0'), "1>>", sign.toString(16).padStart(2, '0'),
    //         //     bytes[i].toString(16).padStart(2, '0'),
    //         //     (((bytes[i] & 0xFF) << 8)).toString(16).padStart(4, '0'),
    //         //     currval.toString(16).padStart(4, '0'));
    //         //     }
    //     } else {
    //         const oldval = currval;
    //         currval |= (bytes[i] & 0xFF);
    //         if (sign) {
    //             currval = 0xFFFF0000 | currval;  // fill in most significant bits with 1's
    //         }
    //         currval = dv.getInt16(i-1, true)
    //         // if(i > 120 &&  i < 300) {
    //         //     console.log(i.toString().padStart(3,'0'), "2>>",
    //         //         oldval.toString(16).padStart(2, '0'),
    //         //         (bytes[i] & 0xff).toString(16).padStart(4, '0'),
    //         //         currval.toString(16).padStart(4, '0'),
    //         //         currval
    //         //         );
    //         // }
    //         if (samples.length < this.DOWNSCALE_FACTOR) {
    //             samples.push(currval);
    //             continue;
    //         }
    //         currval = prev_peak < 0 ? Math.max(...samples) : Math.min(...samples)
    //         peakdata.push(currval / 16384);
    //         // console.log(samples);
    //         // samples = [];
    //         // console.log(bytes.toString());
    //     }
    // }

    // const dv = new DataView(bytes.buffer);
    for (let i = 0; i < bytes.length; i += this.BYTES_PER_SAMPLE) {
      if (samples.length < this.DOWNSCALE_FACTOR) {
        samples.push(dv.getInt16(i, true));
        continue;
      }
      console.log('Squashed', samples.length, 'samples into 1 peak data value, index=', i);
      currval = prev_peak < 0 ? Math.max(...samples) : Math.min(...samples);
      peakdata.push(currval / 16384);
      console.log('Peak data length: ', peakdata.length);
      prev_peak = currval;
      // console.log(samples);
      samples = [];
    }
    console.log(
      'processed',
      bytes.length,
      'bytes, ',
      bytes.length / 6000,
      ' seconds at 3000 sample rate 16 bit samples',
    );
    console.log(
      'Result peak data',
      peakdata.length,
      'samples, ',
      peakdata.length / this.samplerate,
      ' seconds at ',
      this.samplerate,
      ' sample rate',
    );

    const _peaksize = peakdata.length;
    const _newpeakseek = this.peakseek + _peaksize;

    const newTimeLoaded = _newpeakseek / this.samplerate;
    console.log('Processed ', newTimeLoaded, ' seconds of audio data');

    // console.log(Date.now(), "Peak seek:", this.peakseek, "New data chunk size: ", _peaksize, "New peak seek", _newpeakseek);
    // console.log("Data:", peaks);
    // let _dur = this.datasize / this.samplerate;
    if (this.datasize < _newpeakseek) {
      const increment = Math.max(this.dur_increment, _peaksize);
      // console.log("Increasing initial datasize", this.datasize, "bytes, ", _dur, "seconds by ", increment, " bytes, ", increment / this.samplerate, "seconds");
      // console.log("Peaks array has to resize to fit new data from ", this.datasize, " to [at least] ", _newpeakseek);
      this.datasize = this.datasize + increment;
      this.duration = this.datasize / this.samplerate;
      // console.log("Increasing peaks from ", this.peaks.length, "  by ", increment,
      //     " to ", this.datasize, "(", this.peaks.length + increment, ")");
      this.peaks = this.peaks.concat(new Array(increment).fill(0));
      // console.log("New size", this.peaks.length);
    }
    // console.log("New duration", _dur);

    // console.log("Data:", bytes.join(", "));
    // console.log("Peak Data:", peakdata.join(", "));
    // console.log("Updating from ", peakseek, " to ", peakseek + peakdata.length);
    // let lki = 0;
    try {
      for (let i = 0; i < _peaksize; i++) {
        // lki = i;
        this.peaks[this.peakseek + i] = peakdata[i];
      }
    } catch (e) {
      console.log(Date.now(), 'Error', e);
      // console.log(Date.now(), "Peaks", this.peaks.length, "position", this.peakseek + lki, "Data", peakdata.length, "Position", lki);
      throw e;
    }
    this.peakseek += _peaksize;

    // console.log("Buffer size", this.peaks.length, " bytes, ", this.peaks.length/this.samplerate, " seconds")
    // console.log("Tracking at", this.peakseek, "bytes, ", this.peakseek/this.samplerate, " seconds");
  }
}
