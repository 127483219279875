/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */

import {
  Fragment,
  RegionDefaultColor,
  RegionSelectedColor,
} from '@/components/Videoplayer/AVDisplay';
import { ExtRegion, ExtRegionParams } from '@/components/Videoplayer/Waveform';
import { WorkbenchContext } from '@/contexts/WorkbenchContext';
import { useContext, useState } from 'react';

const createRegionParams = (fragment: Fragment, selected: boolean): ExtRegionParams => {
  return {
    id: fragment.id?.toString(),
    start: fragment.timeStartAdjusted / 100,
    end: fragment.timeEndAdjusted / 100,
    color: selected ? RegionSelectedColor : RegionDefaultColor,
    resize: !fragment.locked,
    drag: !fragment.locked,
    locked: fragment.locked,
    fragment: fragment,
    assigned: fragment.studentId != null,
    selected: selected,
    updating: true,
  } as ExtRegionParams;
};
export interface RegionLoader {
  region: any;
  selectedRegions: ExtRegionParams[];
  setSelectedRegions(selectedRegions: ExtRegionParams[]): void;
  reloadRegions?(): void;
  handleRegionsAssigned?(): void;
  handleRegionsLocked?(): void;
  handleRegionsDeleted?(): void;
  handleRegionUpdated?(region: ExtRegion): void;
  handleRegionCreated?(): void;
  handleRegionsVisibilityChanged?(): void;
  handleRegionsProduce?(): void;
}

export function useRegions(): RegionLoader {
  const [region, setRegions] = useState({
    fragments: [] as Fragment[],
  });
  const {
    currentPosition,
    fragments,
    setFragments,
    regionParams: regions,
  } = useContext(WorkbenchContext);
  const [selectedRegions, setSelectedRegions] = useState<ExtRegionParams[]>([]);

  const handleRegionUpdated = (region: ExtRegion): void => {
    const fragment: Fragment = region.fragment;
    console.log('Region Updated', fragment);
    if (!fragment) return;
    fragment.timeStartAdjusted = Math.trunc(region.start * 100);
    fragment.timeEndAdjusted = Math.trunc(region.end * 100);
    const fragments = region.fragment;
    console.log('Region Updated', fragments);
    // storeApi.setState((state) => {})
    //TODO figure out zombie/stale state issue
    setRegions((state) => {
      return { ...state, fragments: [...state.fragments] };
    });
  };

  const handleRegionCreated = (): void => {
    const time = currentPosition as number;
    console.log('Creating region at', time);
    const fragment: Fragment = {} as Fragment;
    fragment.timeStart = Math.trunc(time * 100);
    fragment.timeEnd = Math.trunc((time + 10) * 100);
    fragment.timeStartAdjusted = Math.trunc(time * 100);
    fragment.timeEndAdjusted = Math.trunc((time + 10) * 100);
    fragment.disabled = false;
    fragment.locked = false;
    fragment.graduationDate = new Date().toISOString();
    fragment.workerId = Math.random().toString();
    fragment.sequenceNumber = -1;
    const _fragments = [...fragments, fragment].sort(
      (a, b) => a.timeStartAdjusted - b.timeStartAdjusted,
    );
    setFragments(_fragments);
  };

  const handleRegionsAssigned = (): void => {
    if (!selectedRegions.length) return;
    console.log('Selected Regions', selectedRegions);
    setFragments([...fragments]);
    const _lastSelected = selectedRegions[selectedRegions.length - 1];
    setSelectedRegions([createRegionParams(_lastSelected.fragment, true)]);
    const idx = regions.findIndex((region) => region.id === _lastSelected.id);
    const next = regions[idx + 1];
    if (next) {
      setSelectedRegions([regions[idx + 1]]);
    }
  };

  return {
    region,
    selectedRegions,
    setSelectedRegions,
    handleRegionUpdated,
    handleRegionCreated,
    handleRegionsAssigned,
  };
}
