/* eslint-disable @typescript-eslint/no-unsafe-return */
import WaveSurfer from 'wavesurfer.js';
import PeakDataProcessor from './PeakDataProcessor';

export interface Worker {
  id: string;
  name: string;
  endpointUrl: string;
  streamUrl: string;
  lastSeen: string;
  lastSeenDelta: number;
  graduationDate: string;
  converting: boolean;
}

const audioContext = new AudioContext();
let isLoading = false;

export class PeakDataLoader {
  uuid = Math.random().toString(36).substring(7);
  pollInterval = 1000;
  started = false;
  waveSurfer: WaveSurfer | null = null;
  streamUrl: string | null = null;
  pdp: PeakDataProcessor | null = null;
  responseData: number[] | null = null;

  public start(): void {
    if (this.started) {
      return;
    }
    this.started = true;
    // this.next();
  }

  public update(waveSurfer: WaveSurfer | undefined, url: string, pdp: PeakDataProcessor): void {
    this.waveSurfer = waveSurfer ? waveSurfer : null;
    this.streamUrl = url;
    this.pdp = pdp;
    this.next();
  }

  public next(): void {
    if (isLoading) return;
    isLoading = true;
    this.loadNextChunk()
      .catch(console.error)
      .finally(() => (isLoading = false));
    window.setTimeout(this.next.bind(this), this.pollInterval);
  }

  public async loadNextChunk(): Promise<void> {
    if (!this.streamUrl) return;
    if (!this.pdp) return;
    const pdp = this.pdp;
    await load_chunk(this.streamUrl, async (data: Uint8Array) => {
      try {
        const audioBuffer = await audioContext.decodeAudioData(data.buffer as ArrayBuffer);
        const audioArray = audioBufferToArray(audioBuffer);
        pdp.updatePeakData(audioArray as number[]);
        await pdp.updateWavesurfer(this.waveSurfer as WaveSurfer);
      } catch (e) {
        console.error(e);
      }
    });
  }
}

const delay = (ms: number): Promise<void> => new Promise((resolve) => setTimeout(resolve, ms));

const load_chunk = async (url: string, onChunk: (chunk: Uint8Array) => void): Promise<void> => {
  const response = await fetch(url);
  if (!response.body) {
    throw new Error('Stream not supported');
  }
  const reader = response.body.getReader();

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition, no-constant-condition
  while (true) {
    const { done, value } = await reader.read();
    if (done) break;
    await delay(4000);

    // console.log('Chunk Data:', value);
    onChunk(value);
  }
};

const audioBufferToArray = (
  audioBuffer: AudioBuffer,
  useNumberArray = false,
): Float32Array | number[] => {
  const { numberOfChannels, length } = audioBuffer;
  const mergedData = new Float32Array(length);

  for (let i = 0; i < length; i++) {
    let sum = 0;
    for (let channel = 0; channel < numberOfChannels; channel++) {
      sum += audioBuffer.getChannelData(channel)[i];
    }
    mergedData[i] = sum / numberOfChannels;
  }

  return useNumberArray ? Array.from(mergedData) : mergedData;
};
